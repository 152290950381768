
import { Component } from '@/lib/decorator';
import { ref } from 'vue';

import authModule from '@/store/auth';
import Form from '@/layouts/Form';
import FormInput from '@/components/form/Input/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';
import Loading from '@/components/Loading.vue';
import { GuiButton } from '@library/gigant_ui';

interface Styles {
  root: string;
  userMenu: string;
  userName: string;
  isHidden: string;
}

@Component({
  components: {
    FormInput,
    FormButton,
    Loading,
    GuiButton,
  },
})
export default class LoginUser extends Form {
  autofill = false;
  enterDisabled = true;
  index = 0;
  $style!: Styles;

  form = ref(null);
  login = '';
  password = '';
  validate = formValidate(this.form);
  isLoading = false;
  error = '';

  handlerUpdate() {
    this.error = '';
  }

  toHome() {
    this.$router.push({ name: 'home' });
  }

  auth() {
    this.blockQuery(async () => {
      this.isLoading = true;
      this.error = '';

      const isError = await this.validate.validate();

      if (!isError) {
        this.isLoading = false;

        return;
      }

      try {
        const result = await authModule.loginRequest({
          login: this.login,
          password: this.password,
        });

        if (result.error) {
          this.error = result.message;
          this.isLoading = false;

          return;
        }

        this.isLoading = true;
        this.toHome();
      } catch (e) {
        console.debug('error');
      }
    });
  }

  setAutofill() {
    const input: any = document.querySelector('.cd-page-auth input');

    input.addEventListener('animationstart', (e: any) => {
      if (e.animationName === 'autofill') {
        this.autofill = true;
      }
    });
  }

  mounted() {
    this.setAutofill();
  }

  updated() {
    this.setAutofill();
  }
}
